import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Grid, useMediaQuery, useTheme, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from './apiService';
import TeacherRegisterInput from './TeacherRegisterInput';

function TeacherSchoolSearch() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [searchParam, setSearchParam] = useState({
        schoolCode: "",
        schoolName: "",
        schoolGrade: "",
        schoolZipcode: "",
        schoolAddress1: "",
        schoolAddress2: "",
        teacherPasswd: ""
    });

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
        rowsPerPageOptions: [5, 10, 20],
    });

    const inputRef = useRef(null);

    const [selectedSchoolCode, setSelectedSchoolCode] = useState(null);
    const [selectedSchoolName, setSelectedSchoolName] = useState(null);

    const [open, setOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const columns = [
        {
            field: 'schoolName',
            headerName: '학교명',
            width: isMobile ? 150 : 300,
            flex: isMobile ? 1 : undefined
        },
        {
            field: 'schoolAddress1',
            headerName: '주소',
            flex: 1
        },
        {
            field: 'actions',
            headerName: '등록하기',
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpen(params.row)}
                    sx={{ fontSize: '1.2rem' }}
                >
                    교원 회원 가입
                </Button>
            ),
            width: isMobile ? 100 : 150,
            flex: isMobile ? 0 : undefined
        }
    ].filter(Boolean);

    const handleOpen = (record) => {
        setSelectedRecord(record);
        setSelectedSchoolCode(record.schoolCode);
        setSelectedSchoolName(record.schoolName);
        setIsRegisterModalOpen(true);
    };

    const schoolGradeOptions = [
        { schoolGrade: 'E', name: '초' },
        { schoolGrade: 'M', name: '중' },
        { schoolGrade: 'H', name: '고' },
    ];

    const handleSearch = (overrideSchoolName = null) => {
        const schoolNameToSearch = overrideSchoolName !== null ? overrideSchoolName : searchParam.schoolName;

        if (!searchParam.schoolGrade) {
            setWarningMessage("학교급을 선택하세요.");
            setIsWarningModalOpen(true);
            return;
        }

        if (!schoolNameToSearch.trim()) {
            setWarningMessage("학교명을 입력하세요.");
            setIsWarningModalOpen(true);
            return;
        }

        if (schoolNameToSearch.length < 2 || ["초등", "학교", "초등학교", "중학교", "고등학교", "중등", "고등", "부설", "초", "중", "고", "초등학", "중학", "고등학"].includes(schoolNameToSearch.trim())) {
            setWarningMessage("검색어가 너무 짧거나 일반적인 단어입니다. 구체적인 학교명을 입력해 주세요.");
            setIsWarningModalOpen(true);
            return;
        }
        
        const paramsToSearch = { ...searchParam, schoolName: schoolNameToSearch };

        api.post("/schoolmgr/school/search", paramsToSearch)
            .then(response => {
                setData(response.data.data);
            })
            .catch(error => {
                console.error("Search API fetch error:", error);
            });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch(inputRef.current.value);
            e.preventDefault();
        }
    };

    const handleCloseRegisterModal = () => {
        setIsRegisterModalOpen(false);
    };

    const handleCloseWarningModal = () => {
        setIsWarningModalOpen(false);
    };

    useEffect(() => {
        // 초기 데이터 로드 또는 다른 필요한 작업
    }, [data]);

    return (
        <div className="wrap">
            <div id="container" className="container page-teacher">
                <div className="content-w-form">
                    <h2 className="title-type001">
                        <strong className="tit01">교원 회원가입</strong>
                    </h2>

                    <div className="form-inner">
                        <fieldset className="fieldset-type01">
                            <div className="select-wrap">
                                <select
                                    className="select-type01"
                                    value={searchParam.schoolGrade}
                                    onChange={(e) => setSearchParam({ ...searchParam, schoolGrade: e.target.value })}
                                >
                                    <option value="" disabled>학교급을 선택하세요</option>
                                    {schoolGradeOptions.map(option => (
                                        <option key={option.schoolGrade} value={option.schoolGrade}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <form className="input-group01">
                                <div className="input-type01">
                                    <label htmlFor="searchSchool" className="hide">학교명</label>
                                    <input
                                        id="searchSchool"
                                        type="text"
                                        placeholder="학교명"
                                        value={searchParam.schoolName}
                                        onChange={(e) => setSearchParam({ ...searchParam, schoolName: e.target.value })}
                                        ref={inputRef}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn-type005 blue"
                                    onClick={() => handleSearch(inputRef.current.value)}
                                >
                                    검색
                                </button>
                            </form>
                        </fieldset>

                        <div className="formBox-notice">
                            <div className="title-type002">
                                <strong className="tit01">학교급을 올바르게 선택하고 소속 학교를 검색하세요.</strong>
                                <strong className="tit01">소속 학교가 검색되지 않으면 상단에서 선택한 지역이 올바른지 확인하세요. </strong> 
                                <strong className="tit01">문제가 있을 경우 학교관리자에게 문의하세요.</strong>
                            </div>
                        </div>

                        <div className="formDatagrid-w" style={{ backgroundColor: '#fff', height: '500px' }}>
                            {data && data.length > 0 ? (
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    getRowId={(row) => row.schoolCode}
                                    sx={{
                                        '& .MuiDataGrid-columnHeaders': {
                                            backgroundColor: 'lightgray',
                                            fontSize: '1.2rem',
                                        },
                                        '& .MuiDataGrid-cell': {
                                            fontSize: '1.1rem',
                                        },
                                        backgroundColor: '#fff',
                                    }}
                                />
                            ) : (
                                <div className="title-type002">
                                    <strong className="tit01">검색된 학교가 없습니다.</strong>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={isRegisterModalOpen} onClose={handleCloseRegisterModal}>
                <DialogContent>
                    <TeacherRegisterInput
                        open={isRegisterModalOpen}
                        schoolCode={selectedSchoolCode}
                        schoolName={selectedSchoolName}
                        onClose={handleCloseRegisterModal}
                    />
                </DialogContent>
            </Dialog>

            <Dialog open={isWarningModalOpen} onClose={handleCloseWarningModal}>
                <DialogTitle>경고</DialogTitle>
                <DialogContent>
                    <p>{warningMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseWarningModal} color="primary">
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default TeacherSchoolSearch;