import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import api from './apiService';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '30%',
        maxWidth: '800px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

function ManagerRegisterInput({ open, onClose, schoolCode, schoolName }) {
    const [managerData, setManagerData] = useState({
        schoolManagerId: "",
        schoolManagerPasswd: "",
        schoolManagerName: "",
        schoolManagerPhone: "",
        registerDate: new Date().toISOString().split('T')[0] + 'T09:00:00',
        updatetimeDate: null,
        schoolCode: schoolCode,
        schoolDTO: {
            schoolCode: schoolCode,
            schoolName: schoolName,
            schoolGrade: "",
            schoolZipcode: "",
            schoolAddress1: "",
            schoolAddress2: "",
            teacherPasswd: ""
        }
    });
    const [error, setError] = useState('');
    const [isManagerExists, setIsManagerExists] = useState(false);

    useEffect(() => {
        if (open && schoolCode) {
            api.post('/schoolmgr/schoolmanager/search', managerData)
                .then(response => {
                    if (response.status === 200 && response.data.data) {
                        setError('해당 학교에 이미 학교관리자가 있습니다.');
                        setIsManagerExists(true);
                    } else {
                        setIsManagerExists(false);
                    }
                })
                .catch(error => {
                    console.error('Error fetching manager data', error);
                });
        }
    }, [open, schoolCode, managerData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setManagerData({
            ...managerData,
            [name]: value
        });
    };

    const handleSubmit = async () => {
        const { schoolManagerId, schoolManagerPasswd, schoolManagerName, schoolManagerPhone } = managerData;

        if (!schoolManagerId) {
            alert('학교관리자 ID를 입력하세요.');
            return;
        }
        if (!schoolManagerPasswd) {
            alert('비밀번호를 입력하세요.');
            return;
        }
        if (!schoolManagerName) {
            alert('성명을 입력하세요.');
            return;
        }
        if (!schoolManagerPhone) {
            alert('연락처를 입력하세요.');
            return;
        }

        // 아이디 존재 여부 확인
        try {
            const response = await api.get(`/schoolmgr/schoolmanager/${schoolManagerId}`);
            if (response.status === 200 && response.data.data) {
                alert('아이디가 존재합니다. 다른 아이디를 사용하세요.');
                return;
            }
        } catch (error) {
            if (error.response && error.response.status !== 404) {
                alert('아이디 확인 중 오류가 발생했습니다.');
                return;
            }
        }

        if (!window.confirm('학교관리자를 등록하시겠습니까?')) {
            return;
        }

        try {
            const response = await api.post('/schoolmgr/schoolmanager', managerData);
            if (response.status >= 200 && response.status < 300) {
                alert('학교관리자 등록에 성공했습니다.');
                onClose();
            } else {
                alert(`학교관리자 등록에 실패했습니다. 상태 코드: ${response.status}`);
            }
        } catch (error) {
            alert(`학교관리자 등록에 실패했습니다. 오류 메시지: ${error.message}`);
            console.error("There was an error registering the manager", error);
        }
    };

    return (
        <StyledDialog open={open} onClose={onClose}>
            <DialogTitle>학교관리자 등록</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {error || '학교 정보를 확인하고 등록하세요(오류 시 교육청에 문의)'}
                </DialogContentText>
                {!isManagerExists && (
                    <>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="schoolManagerId"
                            label="학교관리자 ID"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={managerData.schoolManagerId}
                            onChange={handleInputChange}
                        />

                        <TextField
                            margin="dense"
                            name="schoolManagerPasswd"
                            label="비밀번호"
                            type="password"
                            fullWidth
                            variant="outlined"
                            value={managerData.schoolManagerPasswd}
                            onChange={handleInputChange}
                        />

                        <TextField
                            margin="dense"
                            name="schoolManagerName"
                            label="성명"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={managerData.schoolManagerName}
                            onChange={handleInputChange}
                        />
                        <TextField
                            margin="dense"
                            name="schoolManagerPhone"
                            label="연락처"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={managerData.schoolManagerPhone}
                            onChange={handleInputChange}
                        />
                        <TextField
                            margin="dense"
                            name="schoolCode"
                            label="학교코드"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={managerData.schoolCode}
                            disabled={true}
                        />
                        <TextField
                            margin="dense"
                            name="schoolName"
                            label="학교명"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={managerData.schoolDTO.schoolName}
                            disabled={true}
                        />

                        <TextField
                            margin="dense"
                            name="registerDate"
                            label="등록일시"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={managerData.registerDate}
                            disabled={true}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} disabled={isManagerExists}>
                    등록
                </Button>
                <Button onClick={onClose}>취소</Button>
            </DialogActions>
        </StyledDialog>
    );
}

export default ManagerRegisterInput;
