import React, { useState, useEffect } from 'react';
import api from './apiService';

function TeacherRegisterInput({ open, onClose, schoolCode, schoolName }) {
    const [teacherData, setTeacherData] = useState({
        teacherId: "",
        teacherPasswd: "",
        teacherType: "",
        schoolYear: "",
        schoolClass: "",
        teacherName: "",
        registerDate: new Date().toISOString().split('T')[0] + 'T09:00:00',
        schoolCode: schoolCode
    });

    const [classData, setClassData] = useState({
        schoolYear: [],
        schoolClass: []
    });
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [idValidated, setIdValidated] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [teacherAuthNum, setTeacherAuthNum] = useState();
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [messageTitle, setMessageTitle] = useState("");
    const [messageContent, setMessageContent] = useState("");
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setTeacherData(prevSchoolData => ({
            ...prevSchoolData,
            schoolName: schoolName
        }));
    }, [schoolName]);

    const handleAuthenticate = async () => {
        try {
            const response = await api.post(`/schoolmgr/school/teacherAuth`, {
                schoolCode: schoolCode,
                teacherPasswd: teacherAuthNum,
            });

            if (response.status === 200 && response.data.data) {
                setIsAuthenticated(true);
            } else {
                setMessageTitle("인증 실패");
                setMessageContent("교원인증번호를 올바르게 입력하세요. 교원인증번호는 학교관리자에 문의하세요.");
                setMessageModalOpen(true);
            }
        } catch (error) {
            setMessageTitle("인증 실패");
            setMessageContent("교원인증번호를 올바르게 입력하세요. 교원인증번호는 학교관리자에 문의하세요." + error.message);
            setMessageModalOpen(true);
        }
    };

    const fetchClassData = async () => {
        try {
            const response = await api.get(`/school/class?schoolCode=${schoolCode}`);
            if (response.status === 200 && response.data.data) {
                const fetchedData = response.data.data;
                const uniqueYears = new Set();
                const uniqueClasses = new Set();

                fetchedData.forEach(item => {
                    uniqueYears.add(item.schoolYear);
                    uniqueClasses.add(item.schoolClass);
                });

                setClassData({
                    schoolYear: [...uniqueYears],
                    schoolClass: [...uniqueClasses]
                });

            } else {
                setClassData(generateDefaultClassData());
            }
        } catch (error) {
            console.error("Error fetching class data:", error);
            setClassData(generateDefaultClassData());
        }
    };

    const generateDefaultClassData = () => {
        const defaultData = {
            schoolYear: [],
            schoolClass: []
        };

        for (let year = 1; year <= 6; year++) {
            defaultData.schoolYear.push(year);
            for (let classNum = 1; classNum <= 10; classNum++) {
                if (year === 1) {
                    defaultData.schoolClass.push(classNum);
                }
            }
        }

        return defaultData;
    };

    useEffect(() => {
        setTeacherData(prev => ({
            ...prev,
            schoolYear: selectedYear,
            schoolClass: selectedClass
        }));
    }, [selectedYear, selectedClass]);

    useEffect(() => {
        if (schoolCode) {
            fetchClassData();
        }
    }, [schoolCode]);

    const validateIdInput = (input) => {
        // 허용되지 않는 문자: 공백, 특수 문자 등
        const invalidChars = /[\s!@#$%^&*(),.?":{}|<>]/;
        return !invalidChars.test(input);
    };

    const validatePasswordInput = (input) => {
        // 허용되지 않는 문자: 공백
        const invalidChars = /\s/;
        return !invalidChars.test(input);
    };

    const handleIdCheck = async () => {
        if (teacherData.teacherId.length > 10) {
            setMessageTitle("ID 길이 확인");
            setMessageContent('아이디는 10자리 이내로 작성해 주세요.');
            setMessageModalOpen(true);
            return;
        }

        if (!validateIdInput(teacherData.teacherId)) {
            setMessageTitle("ID Check");
            setMessageContent('아이디에 공백이나 허용되지 않는 문자가 포함될 수 없습니다.');
            setMessageModalOpen(true);
            return;
        }

        try {
            const response = await api.get(`/schoolmgr/teacher/${teacherData.teacherId}`);

            if (response.status === 200) {
                setMessageTitle("ID Check");
                setMessageContent('해당 아이디가 존재합니다.');
                setMessageModalOpen(true);
                setIdValidated(false);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setMessageTitle("ID Check");
                setMessageContent('해당 아이디로 사용이 가능합니다.');
                setMessageModalOpen(true);
                setIdValidated(true);
            } else {
                console.error('An error occurred:', error);
                setErrorMessage('An unexpected error occurred.');
                setErrorModalOpen(true);
            }
        }
    };

    const handleTeacherTypeChange = (event) => {
        const newType = event.target.value;
        setTeacherData({ ...teacherData, teacherType: newType });

        if (newType === 'B') {
            setSelectedYear('');
            setSelectedClass('');
        }
    };

    const handleSave = async () => {
        if (!validatePasswordInput(teacherData.teacherPasswd)) {
            setMessageTitle("Password Check");
            setMessageContent('패스워드에 공백이 포함될 수 없습니다.');
            setMessageModalOpen(true);
            return;
        }

        const dataToSave = {
            teacherId: teacherData.teacherId,
            teacherPasswd: teacherData.teacherPasswd,
            teacherType: teacherData.teacherType,
            schoolYear: teacherData.schoolYear,
            schoolClass: teacherData.schoolClass,
            teacherName: teacherData.teacherName,
            registerDate: new Date().toISOString().split('T')[0] + 'T09:00:00',
            schoolCode: teacherData.schoolCode
        };
        try {
            const response = await api.post(`/schoolmgr/teacher`, dataToSave);
            if (response.status === 200) {
                setMessageTitle("가입 성공");
                // setMessageContent(response.data.data);
                setMessageContent("교원 회원가입이 완료되었습니다.화면을 닫은 후 로그인을 진행하세요.");
                setMessageModalOpen(true);
            } else {
                setErrorMessage(`오류: ${response.status} - ${response.data.error}`);
                setErrorModalOpen(true);
            }
        } catch (error) {
            setErrorMessage(error.message || 'An unexpected error occurred.');
            setErrorModalOpen(true);
        }
    };

    const resetFields = () => {
        setIdValidated(false);
        setTeacherData({
            teacherId: "",
            teacherPasswd: "",
            teacherType: "",
            schoolYear: "",
            schoolClass: "",
            teacherName: "",
            registerDate: new Date().toISOString().split('T')[0] + 'T09:00:00',
            schoolCode: schoolCode
        });
        setClassData({
            schoolYear: [],
            schoolClass: []
        });
        fetchClassData();
    };

    return (
        <div id="modalJoin2" className={`modal-w ${open ? 'active' : ''}`} aria-modal="true">
            <style>
                {`
                    .disabled-input {
                        color: gray;
                    }
                `}
            </style>
            <div className="modal-inner-type45">
                <div className="title-type001">
                    <strong className="tit01">교원 회원가입</strong>
                </div>

                <fieldset className="fieldset-type02">
                    {!isAuthenticated && (
                        <>
                            <div className="input-type01">
                                <label htmlFor="teacherAuthNum" className="hide">교원인증번호</label>
                                <input
                                    id="teacherAuthNum"
                                    type="text"
                                    placeholder="교원인증번호"
                                    value={teacherAuthNum}
                                    onChange={(e) => setTeacherAuthNum(e.target.value)}
                                />
                            </div>
                            <button type="button" className="btn-type005 blue" onClick={handleAuthenticate}>인증</button>
                        </>
                    )}
                    {isAuthenticated && (
                        <>
                            <div className="input-type01">
                                <label htmlFor="schoolCode" className="hide">학교코드</label>
                                <input
                                    id="schoolCode"
                                    type="text"
                                    value={schoolCode}
                                    disabled={true}
                                    className="disabled-input"
                                />
                            </div>

                            <div className="input-type01">
                                <label htmlFor="schoolName" className="hide">학교이름</label>
                                <input
                                    id="schoolName"
                                    type="text"
                                    value={schoolName}
                                    disabled={true}
                                    className="disabled-input"
                                />
                            </div>

                            <div className="input-type01">
                                <label htmlFor="teacherId" className="hide">아이디(최대 10자리)</label>
                                <input
                                    id="teacherId"
                                    type="text"
                                    placeholder="아이디(최대 10자리)"
                                    value={teacherData.teacherId}
                                    onChange={(e) => setTeacherData({ ...teacherData, teacherId: e.target.value })}
                                    disabled={idValidated}
                                    className={idValidated ? "disabled-input" : ""}
                                />
                            </div>
                            <button type="button" className="btn-type005 blue" onClick={handleIdCheck}>아이디 중복확인</button>

                            <div className="input-type01">
                                <label htmlFor="teacherPasswd" className="hide">패스워드</label>
                                <input
                                    id="teacherPasswd"
                                    type="text"
                                    placeholder="패스워드"
                                    value={teacherData.teacherPasswd}
                                    onChange={(e) => setTeacherData({ ...teacherData, teacherPasswd: e.target.value })}
                                />
                            </div>

                            <div className="input-type01">
                                <label htmlFor="teacherName" className="hide">성명</label>
                                <input
                                    id="teacherName"
                                    type="text"
                                    placeholder="성명"
                                    value={teacherData.teacherName}
                                    onChange={(e) => setTeacherData({ ...teacherData, teacherName: e.target.value })}
                                />
                            </div>

                            <div className="radio-form">
                                <span className="input-radio01">
                                    <input type="radio" id="type01" name="types" className="radio01" value="C" checked={teacherData.teacherType === 'C'} onChange={handleTeacherTypeChange} />
                                    <label htmlFor="type01" className="txt-label01">담임</label>
                                </span>
                                <span className="input-radio01">
                                    <input type="radio" id="type02" name="types" className="radio01" value="B" checked={teacherData.teacherType === 'B'} onChange={handleTeacherTypeChange} />
                                    <label htmlFor="type02" className="txt-label01">비담임</label>
                                </span>
                            </div>

                            <select id="schoolYear" name="" className="select-type01" value={selectedYear} onChange={e => setSelectedYear(e.target.value)} disabled={teacherData.teacherType === 'B'}>
                                <option disabled="" selected="" hidden="" name="">학년을 선택하세요</option>
                                {classData.schoolYear.map((item, index) => (
                                    <option key={index} value={item}>{item}학년</option>
                                ))}
                            </select>

                            <select id="schoolClass" name="" className="select-type01" value={selectedClass} onChange={e => setSelectedClass(e.target.value)} disabled={teacherData.teacherType === 'B'}>
                                <option disabled="" selected="" hidden="" name="">반을 선택하세요</option>
                                {classData.schoolClass.map((item, index) => (
                                    <option key={index} value={item}>{item}반</option>
                                ))}
                            </select>

                            <div className="btns-group03">
                                <button type="button" className="btn-type005 blue" onClick={handleSave} disabled={!idValidated}>저장</button>
                                <button type="button" className="btn-type005 border" onClick={resetFields}>취소</button>
                            </div>
                        </>
                    )}
                </fieldset>

                <button type="button" className="btn-type005 border close" onClick={onClose}>
                    <span className="txt01">닫기</span>
                </button>
            </div>

            {/* Message Modal */}
            {messageModalOpen && (
                <div id="modalMessage" className="custom-modal active" aria-modal="true">
                    <div className="modal-inner-type25">
                        <div className="title-type001">
                            <strong className="tit01">{messageTitle}</strong>
                        </div>
                        <div className="title-type003">
                            <strong className="tit01-grey">{messageContent}</strong>
                        </div>
                        <button type="button" className="btn-type005 border close" onClick={() => setMessageModalOpen(false)}>
                            <span className="txt01">닫기</span>
                        </button>
                    </div>
                    <i className="dimm"></i>
                </div>
            )}

            {/* Error Modal */}
            {errorModalOpen && (
                <div id="modalError" className="custome-modal active" aria-modal="true">
                    <div className="modal-inner-type25">
                        <div className="title-type001">
                            <strong className="tit01">Error</strong>
                        </div>
                        <div className="title-type003">
                            <strong className="tit01-grey">{errorMessage}</strong>
                        </div>
                        <button type="button" className="btn-type005 border close" onClick={() => setErrorModalOpen(false)}>
                            <span className="txt01">닫기</span>
                        </button>
                    </div>
                    <i className="dimm"></i>
                </div>
            )}
        </div>
    );
}

export default TeacherRegisterInput;